import { SimpleSubjectDTO, SubjectDTO } from './User';

export interface DocumentOrderDTO {
  id: number;
  created_at: string;
  completed_at?: string;
  document_type: number;
  status: number;
  subject: SimpleSubjectDTO;
  reference_number: string;
}

export interface DocumentOrderDetailsDTO extends Omit<DocumentOrderDTO, 'subject'> {
  subject: SubjectDTO;
  completed_by: string;
  document_name: string;
  comment?: string;
}

export interface DocumentReportDTO {
  start: string;
  end: string;
  channel: DocumentReportChannelDTO;
}

export enum DocumentReportChannelDTO {
  all = 'all',
  debt = 'debt',
  city = 'city',
}

export const documentOrderSortInfoFieldsMap = {
  type: 'document_type',
  user: ['interaction__profile__given_name', 'interaction__profile__family_name'],
  date: 'created_at',
  referenceId: 'interaction__payment_datatrans_reference',
  status: 'status',
};
