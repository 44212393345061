import moment, { Moment } from 'moment';

const dateFormattingOptions: Intl.DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};

const timeFormattingOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

export const dateToSimpleYYYYMMDDString = (date: Date, utc: boolean = false): string => {
  const ye = new Intl.DateTimeFormat(undefined, { year: 'numeric' }).format(date);
  const mo = `0${(utc ? date.getUTCMonth() : date.getMonth()) + 1}`.slice(-2);
  const da = `0${utc ? date.getUTCDate() : date.getDate()}`.slice(-2);
  return `${ye}-${mo}-${da}`;
};

export const dateToDayOfYearString = (date: Date): string => {
  return new Intl.DateTimeFormat(undefined, { month: 'numeric', day: 'numeric' }).format(date);
};

export const dateToSimpleHHSSString = (date: Date, utc: boolean = false): string => {
  const hh = `0${utc ? date.getUTCHours() : date.getHours()}`.slice(-2);
  const ss = `0${utc ? date.getUTCMinutes() : date.getMinutes()}`.slice(-2);
  return `${hh}:${ss}`;
};

export const dateToDateString = (date: Date): string => {
  return date.toLocaleDateString(undefined, dateFormattingOptions);
};

export const dateToDateTimeString = (date: Date | Moment): string => {
  if (date instanceof moment) {
    date = (date as Moment).toDate();
  }
  return date.toLocaleString(undefined, { ...dateFormattingOptions, ...timeFormattingOptions });
};

/**
 * Transforms non Date.parse conforming strings to conforming format
 *
 * @param dotDate Date String in the "dd.MM.yyyy" format
 * @returns Date String in the "yyyy/MM/dd" format
 */
export const dotDateToParseableDate = (dotDate: string): string => {
  return dotDate.split('.').reverse().join('/');
};
