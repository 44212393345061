import { Address } from './Address';
import { HistoryEvent } from './HistoryEvent';

export type Gender = 'male' | 'female';

export enum UserStatus {
  Created = 'user.status.created',
  Declined = 'user.status.declined',
  Verified = 'user.status.verified',
  Revoked = 'user.status.revoked',
  Reinstated = 'user.status.reinstated',
  Unknown = 'user.status.unknown',
}

export interface UserBaseProfile {
  verificationDate: Date;
  firstName: string;
  lastName: string;
  name: string;
  avatar?: string;
  birthDate: Date;
  status: UserStatus;
}

export interface UserDetailsBaseProfile {
  eidAccountAddress: string;
  gender: Gender;
  placeOfOrigin: string;
  nationality: string; // TODO: define enum for all country codes and translations (e.g. Schweiz/Switzerland)
  passportNumber: string;
}

export interface User extends UserBaseProfile {
  id: number;
}

export interface UserDetails extends User, UserDetailsBaseProfile {
  history?: HistoryEvent[];
}

export interface SimpleSubject {
  eidAccountAddress?: string;
  name: string;
  givenName: string;
  familyName: string;
  avatar?: string;
}

export interface Subject extends SimpleSubject {
  birthDate?: Date;
  placeOfOrigin: string;
  address?: Address;
}

export interface VoteAbsenceSubject {
  givenName: string;
  familyName: string;
  birthDate: Date;
}
