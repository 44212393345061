/* istanbul ignore file */
/* eslint-disable no-console */
import * as Sentry from '@sentry/react';

import { isSentryAvailable } from './Sentry';

export const reportError = (msg: string): string => {
  console.error(`[Reported Error] ${msg}`);
  if (isSentryAvailable) {
    return Sentry.captureMessage(msg);
  }
  return '0';
};

export const reportException = (e: Error, ctx?: string): string => {
  console.error(`[Reported Exception] ${e.message}${ctx ? ` - Context: ${ctx}` : ''}`);
  if (isSentryAvailable) {
    return Sentry.captureException(e, {
      tags: {
        customContext: ctx ?? '',
      },
    });
  }
  return '0';
};
