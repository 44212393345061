import { FunctionComponent } from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';

const RootRouter: FunctionComponent = ({ children }) => {
  if (!!process.env.REACT_APP_USE_HASH_ROUTER) {
    return <HashRouter>{children}</HashRouter>;
  } else {
    return <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>{children}</BrowserRouter>;
  }
};

export default RootRouter;
