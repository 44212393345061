import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _mergeWith from 'lodash/mergeWith';
import _omitBy from 'lodash/omitBy';

const concatArrays = (objValue: any, srcValue: any) => {
  if (_isArray(objValue) && _isArray(srcValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
};

const replaceArrays = (objValue: any, srcValue: any) => {
  if (_isArray(objValue)) {
    return srcValue;
  }
  return undefined;
};

const concatReplaceArrays = (objValue: any, srcValue: any) => {
  if (_isArray(objValue) && _isArray(srcValue)) {
    return objValue.concat(srcValue);
  } else if (_isArray(objValue)) {
    return srcValue;
  }
  return undefined;
};

export const deepMergeObj = (...objects: Record<string, any>[]) => {
  return _mergeWith({}, ...objects, replaceArrays);
};

export const deepMergeObjConcatArr = (...objects: Record<string, any>[]) => {
  return _mergeWith({}, ...objects, concatArrays);
};
export const deepMergeObjConcatReplaceArr = (...objects: Record<string, any>[]) => {
  return _mergeWith({}, ...objects, concatReplaceArrays);
};

export const isEmptyValue = (value: any) => {
  if (typeof value === 'object') {
    return _isEmpty(value);
  } else {
    return value === undefined || value === null || value === '';
  }
};

export const omitEmptyFields = (obj: Record<string, any>) => {
  return _omitBy(obj, isEmptyValue);
};
