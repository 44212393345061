export enum TimeSpan {
  Never = 'timeSpan.never',
  Daily = 'timeSpan.daily',
  Weekly = 'timeSpan.weekly',
  Monthly = 'timeSpan.monthly',
  Yearly = 'timeSpan.yearly',
  AllTime = 'timeSpan.all',
  TimeRange = 'timeSpan.range',
  TimeRangeSelector = 'timeSpan.rangeSelector',
}
