import { FromLocation } from '@models/types/Routing';
import LazyRoute, { LazyRouteProps } from '@router/helpers/LazyRoute';
import { LocationDescriptor } from 'history';
import { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';

export interface GuardedRouteProps extends LazyRouteProps {
  allowed: boolean;
  redirectTo: LocationDescriptor<FromLocation>;
}

const GuardedRoute: FunctionComponent<GuardedRouteProps> = ({ allowed, redirectTo, children, ...routeProps }) => {
  return <LazyRoute {...routeProps}>{!!allowed ? children : <Redirect to={redirectTo} />}</LazyRoute>;
};

export default GuardedRoute;
