import { useStores } from '@contexts/store/RootStoreContext';
import { useConfiguration } from '@hooks/api/Configuration';
import { LoadingView } from '@procivis/react-components';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';

export interface DataGuardedRouteProps {}

const DataGuardedRoute: FunctionComponent<DataGuardedRouteProps> = observer(({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [failureTimeout, setFailureTimeout] = useState(false);
  const rootStore = useStores();
  const { configurationStore, userStore } = rootStore;
  const { id: userId } = userStore;

  const configReqErrorHandler = useCallback(() => {
    rootStore.logout();
    history.push('/login', { errorCode: 404 });
  }, [history, rootStore]);

  const {
    data: configurationData,
    isLoading: isConfigLoading,
    isError: isConfigError,
    failureCount: configFailureCount,
  } = useConfiguration(userId, configReqErrorHandler);

  // Setup Configuration
  useEffect(() => {
    if (!isConfigLoading && !isConfigError && configurationData) {
      configurationStore.setConfiguration(configurationData);
    }
  }, [isConfigLoading, isConfigError, configurationData, configurationStore]);

  // Failure Fallback
  useEffect(() => {
    let failureTimer = setTimeout(() => setFailureTimeout(true), 3000);
    return () => {
      clearTimeout(failureTimer);
    };
  }, []);

  const isError = isConfigError;
  const shouldFail = configFailureCount >= 3;

  return configurationStore.isConfigured ? (
    <>{children}</>
  ) : (isError && shouldFail) || (failureTimeout && userId === undefined) ? (
    <Redirect to={{ pathname: '/login', state: { from: location } }} />
  ) : (
    <LoadingView />
  );
});

export default DataGuardedRoute;
