import { LanguageStoreModel } from '@models/store/language/LanguageStoreModel';
import { UserStoreModel } from '@models/store/user/UserStoreModel';
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

import { ConfigurationStoreModel } from './configuration/ConfigurationStoreModel';
import { NetworkStoreModel } from './network/NetworkStoreModel';
import { OrganizationStoreModel } from './organization/OrganizationStoreModel';

export const RootStoreModel = types
  .model('RootStore', {
    languageStore: LanguageStoreModel,
    networkStore: NetworkStoreModel,
    userStore: UserStoreModel,
    organizationStore: OrganizationStoreModel,
    configurationStore: ConfigurationStoreModel,
  })
  // Sync Actions
  .actions((self) => ({
    logout: () => {
      self.networkStore.logout();
      self.userStore.logout();
      self.organizationStore.logout();
      self.configurationStore.logout();
    },
  }));

// Store types
export interface IRootStore extends Instance<typeof RootStoreModel> {}
export interface IRootStoreSnapshotIn extends SnapshotIn<typeof RootStoreModel> {}
export interface IRootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
