import { SimpleSubjectDTO, SubjectDTO, UserDetailsDTO, UserDTO } from '@models/dtos/User';
import { Gender, SimpleSubject, Subject, User, UserDetails, UserStatus } from '@models/types/User';
import { dotDateToParseableDate } from '@utils/dates/Dates';

import { addressDTOParser } from './Address';
import { userDetailsDTOAuditListParser } from './UserHistory';

export const userStatusParser = (status: number): UserStatus => {
  switch (status) {
    case 1:
      return UserStatus.Created;
    case 2:
      return UserStatus.Declined;
    case 3:
      return UserStatus.Verified;
    case 4:
      return UserStatus.Revoked;
    case 5:
      return UserStatus.Reinstated;
    default:
      return UserStatus.Unknown;
  }
};

export const userDTOParser = (userDTO: UserDTO): User => {
  return {
    id: userDTO.id,
    firstName: userDTO.given_name,
    lastName: userDTO.family_name,
    name: `${userDTO.given_name} ${userDTO.family_name}`,
    avatar: userDTO.picture,
    birthDate: new Date(Date.parse(userDTO.birth_date)),
    verificationDate: new Date(Date.parse(userDTO.created_at)),
    status: userStatusParser(userDTO.status),
  };
};

export const userDetailsDTOParser = (userDetailsDTO: UserDetailsDTO): UserDetails => {
  const baseUser = userDTOParser(userDetailsDTO);
  return {
    ...baseUser,
    eidAccountAddress: userDetailsDTO.eid,
    gender: userDetailsDTO.gender as Gender,
    placeOfOrigin: userDetailsDTO.place_of_origin,
    nationality: userDetailsDTO.nationality,
    passportNumber: userDetailsDTO.passport_number,
    history: userDetailsDTOAuditListParser(userDetailsDTO.audit_list),
  };
};

export const simpleSubjectDTOParser = (subjectDTO: SimpleSubjectDTO): SimpleSubject => {
  return {
    eidAccountAddress: subjectDTO.id,
    name: `${subjectDTO.given_name} ${subjectDTO.family_name}`,
    givenName: subjectDTO.given_name,
    familyName: subjectDTO.family_name,
    avatar: subjectDTO.picture,
  };
};

export const subjectDTOParser = (subjectDTO: SubjectDTO): Subject => {
  const simpleSubject = simpleSubjectDTOParser(subjectDTO);
  return {
    ...simpleSubject,
    birthDate: subjectDTO.birth_date ? new Date(Date.parse(dotDateToParseableDate(subjectDTO.birth_date))) : undefined,
    placeOfOrigin: subjectDTO.place_of_origin,
    address: addressDTOParser({
      street: subjectDTO.street,
      zip_code: subjectDTO.zip_code,
      city: subjectDTO.city,
      region: subjectDTO.region,
      country: subjectDTO.country,
    }),
  };
};
