import { ThemeColors, ThemeImages } from '@appconf/models';
import VFSLogo from '@assets/images/VFSLogo.svg';

export const vfsThemeColors: ThemeColors = {
  neutral: '#ffffff',
  primary: '#122f5a',
  primaryVariant: '#122f5a',
  onPrimary: '#ffffff',
  onPrimaryVariant: '#ffffff',
  text: 'rgba(0, 0, 0, 0.85)',
  background: '#f0f2f5',
  componentBackground: '#ffffff',
  orgHighlight: 'orange',
  orgAdminHighlight: 'red',
};

export const vfsThemeImages: ThemeImages = {
  logoURI: VFSLogo,
};
