import { TimeSpan } from '@models/types/TimeSpan';

export const notificationFrequencyParser = (span: number | undefined): TimeSpan | undefined => {
  switch (span) {
    case 1:
      return TimeSpan.Daily;
    case 2:
      return TimeSpan.Weekly;
    case 3:
      return TimeSpan.Monthly;
    default:
      return undefined;
  }
};

export const notificationFrequencyMapper = (span: TimeSpan | undefined): number | undefined => {
  switch (span) {
    case TimeSpan.Daily:
      return 1;
    case TimeSpan.Weekly:
      return 2;
    case TimeSpan.Monthly:
      return 3;
    default:
      return undefined;
  }
};
