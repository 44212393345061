import { AddressDTO } from '@models/dtos/Address';
import { Address } from '@models/types/Address';

export const addressDTOParser = (addressDTO: AddressDTO): Address => {
  return {
    street: addressDTO.street,
    extra: addressDTO.extra,
    municipality: addressDTO.municipality,
    country: addressDTO.country,
    city: addressDTO.city,
    location: addressDTO.region ?? addressDTO.city,
    postcode: addressDTO.zip_code,
  };
};
