import DataGuardedRoute from '@router/guards/DataGuardedRoute';
import MainGuardedRoute from '@router/guards/MainGuardedRoute';
import LazyRoute from '@router/helpers/LazyRoute';
import { FunctionComponent, lazy } from 'react';
import { Switch } from 'react-router-dom';

const LazyAdaptedLoginPage = lazy(() => import('@pages/login/LoginPage.adapter'));
const LazyMainPage = lazy(() => import('@pages/main/MainPage'));
const LazyAdaptedForgotPasswordPage = lazy(() => import('@pages/password/ForgotPassword/ForgotPasswordPage.adapter'));
const LazyAdaptedForgotPasswordConfirmPage = lazy(
  () => import('@pages/password/ForgotPasswordConfirm/ForgotPasswordConfirmPage.adapter')
);
const LazyAdaptedUnlockAccountPage = lazy(
  () => import('@pages/login/accountLock/UnlockAccount/UnlockAccountPage.adapter')
);
const LazyAdaptedConfirmInvitationPage = lazy(
  () => import('@pages/invitation/ConfirmInvitation/ConfirmInvitationPage.adapter')
);

const LazyAdaptedLinkConfirmInvitationPage = lazy(
  () => import('@pages/invitation/ConfirmInvitation/ConfirmLinkingPage.adapter')
);

export interface AppOutletProps {}

const AppOutlet: FunctionComponent<AppOutletProps> = () => {
  return (
    <Switch>
      <LazyRoute path="/login">
        <LazyAdaptedLoginPage />
      </LazyRoute>
      <LazyRoute path="/forgot-password/confirm">
        <LazyAdaptedForgotPasswordConfirmPage />
      </LazyRoute>
      <LazyRoute path="/forgot-password">
        <LazyAdaptedForgotPasswordPage />
      </LazyRoute>
      <LazyRoute path="/unlock-account">
        <LazyAdaptedUnlockAccountPage />
      </LazyRoute>
      <LazyRoute path="/invite/confirm">
        <LazyAdaptedConfirmInvitationPage />
      </LazyRoute>
      <LazyRoute path="/link/confirm">
        <LazyAdaptedLinkConfirmInvitationPage />
      </LazyRoute>
      <MainGuardedRoute>
        <DataGuardedRoute>
          <LazyMainPage />
        </DataGuardedRoute>
      </MainGuardedRoute>
    </Switch>
  );
};

export default AppOutlet;
