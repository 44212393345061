import { SimpleSubject, Subject } from './User';
export enum DocumentType {
  DebtRegistry = 'document.type.debtRegistryExcerpt',
  HomeId = 'document.type.homeId',
  ResidenceCertificate = 'document.type.residenceCertificate',
  CivilRightsCapacity = 'document.type.certificateOfCivilRightsCapacity',
  GoodCharacterCertificate = 'document.type.certificateOfGoodCharacter',
  ResidenceCertificateInOut = 'document.type.residenceCertificateMoveInAndMoveOut',
  Unknown = 'document.type.unknown',
}

export enum DocumentOrderStatus {
  Created = 'documentOrder.status.created',
  Requested = 'documentOrder.status.requested',
  Resolved = 'documentOrder.status.resolved',
  Automatic = 'documentOrder.status.automatic',
  Downloaded = 'documentOrder.status.downloaded',
  Error = 'documentOrder.status.error',
  Unknown = 'documentOrder.status.unknown',
  Canceled = 'documentOrder.status.canceled',
  Declined = 'documentOrder.status.declined',
}

export interface DocumentOrder {
  id: number;
  type: DocumentType;
  status: DocumentOrderStatus;
  user: SimpleSubject;
  date: Date;
  completionDate?: Date;
  referenceId: string;
}

export interface DocumentOrderDetails extends Omit<DocumentOrder, 'user'> {
  user: Subject;
  documentName?: string;
  completedBy?: string;
  comment?: string;
}
