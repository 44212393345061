import { CreateRoleDTO, RoleDetailsDTO, RoleDTO } from '@models/dtos/Role';
import { CreatedRole, Role, RoleDetails } from '@models/types/Role';

import { permissionsDTOParser, permissionsToDTOParser } from './Permission';

export const roleDTOParser = (roleDTO: RoleDTO): Role => {
  return {
    id: roleDTO.id,
    name: roleDTO.name,
    isAdmin: roleDTO.is_admin,
    description: roleDTO.description,
  };
};

export const roleDetailsDTOParser = (roleDetailsDTO: RoleDetailsDTO): RoleDetails => {
  return {
    ...roleDTOParser(roleDetailsDTO),
    permissions: permissionsDTOParser(roleDetailsDTO.permissions),
  };
};

export const createdRoleToDTOParser = (role: CreatedRole): CreateRoleDTO => {
  return {
    name: role.name,
    description: role.description,
    permissions: permissionsToDTOParser(role.permissions),
  };
};
