export const persistData = <T>(localStorageData: T, sessionStorageData: T, storageKey: string): void => {
  localStorage.setItem(storageKey, JSON.stringify(localStorageData));
  sessionStorage.setItem(storageKey, JSON.stringify(sessionStorageData));
};

export const getPersistedData = <T>(storageKey: string): T => {
  const storedData = localStorage.getItem(storageKey);
  const storedSessionData = sessionStorage.getItem(storageKey);

  const storedDataJson = storedData ? JSON.parse(storedData) : {};
  const storedSessionDataJson = storedSessionData ? JSON.parse(storedSessionData) : {};

  const mergedData = { ...storedDataJson, ...storedSessionDataJson };
  return mergedData;
};
