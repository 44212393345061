import { AppConfig } from './models';

/*
 * Export App Flavor based config
 * NOTE: Read "Environments And Flavors" in README.md for more details.
 */
let appConfig: AppConfig;
if (process.env.REACT_APP_TARGET_FLAVOR === 'sh') {
  appConfig = require('./sh/config').default;
} else if (process.env.REACT_APP_TARGET_FLAVOR === 'zug') {
  appConfig = require('./zug/config').default;
} else if (process.env.REACT_APP_TARGET_FLAVOR === 'ar') {
  appConfig = require('./ar/config').default;
} else if (process.env.REACT_APP_TARGET_FLAVOR === 'vfs') {
  appConfig = require('./vfs/config').default;
} else {
  appConfig = require('./procivis/config').default;
}

export default appConfig;
