import appConfig from '@appconf/config';
import { FunctionComponent, SVGProps } from 'react';

export interface TextCrossLogoSVGProps {
  textFillColor?: string;
  crossFillColor?: string;
  svgProps?: SVGProps<SVGSVGElement>;
  textPathProps?: SVGProps<SVGPathElement>;
  crossPathProps?: SVGProps<SVGPathElement>;
}

const TextCrossLogoSVG: FunctionComponent<TextCrossLogoSVGProps> = ({
  textFillColor = appConfig?.theme?.colors?.text,
  crossFillColor = appConfig?.theme?.colors?.primary,
  svgProps = {},
  textPathProps = {},
  crossPathProps = {},
}) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 16" {...svgProps}>
      <path
        d="M49 2.01836V13.7868h-7v-2.4229h2.45V8.94099l2.0999-.00055V6.8636l-2.0999.0006V4.44128h2.1V2.01836H49zm-9.45 9.34554V8.94099h-2.1V6.51807l2.0998-.00025.0002-2.07654h-2.1V2.01836h7v2.42292H42V6.8642l-2.1006-.0006.0006 2.07739H42v2.42291h-2.1v2.4229h-2.45v-2.4229h2.1z"
        fill={crossFillColor ?? '#000000'}
        {...crossPathProps}
      />
      <path
        d="M8.2783 8.76802c-.35701-1.27773-1.36111-1.74035-2.36521-1.74035-1.27185 0-2.11976.61676-2.43215 1.74035H8.2783zM6.22548 12.7775c.91484 0 1.69581-.3525 2.14207-.8372l2.67765 1.5201c-1.09333 1.4981-2.74451 2.2691-4.86435 2.2691C2.36528 15.7295 0 13.196 0 9.91357c0-3.28251 2.40991-5.81594 5.93541-5.81594 3.25781 0 5.66769 2.48944 5.66769 5.81594 0 .46253-.0447.90323-.134 1.32183H3.54781c.42403 1.1455 1.45044 1.5421 2.67767 1.5421zM13.6109 15.421h3.5702V.00000763h-3.5702V15.421zM30.2566 7.71052c0-2.64358-1.6958-4.31792-4.1503-4.31792h-2.6776v8.6358h2.6776c2.4545 0 4.1503-1.6743 4.1503-4.31788zm3.4363 0c0 4.36188-3.2801 7.71048-7.5866 7.71048h-6.2478V.00000763h6.2478c4.3065 0 7.5866 3.34861237 7.5866 7.71051237z"
        fill={textFillColor ?? '#000000'}
        {...textPathProps}
      />
    </svg>
  );
};

export default TextCrossLogoSVG;
