import { LANGUAGE_STORAGE_KEY } from '@models/constants/Storage';
import { LanguageStoreModel } from '@models/store/language/LanguageStoreModel';
import { StoreEnv } from '@store/Environment';

export const setupLanguageStore = (env: StoreEnv) => {
  const languageStore = LanguageStoreModel.create(
    {
      currentLanguage: localStorage.getItem(LANGUAGE_STORAGE_KEY) ?? 'de',
    },
    env
  );

  return languageStore;
};
