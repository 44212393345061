/* istanbul ignore file */
import { RootStoreModel } from '@models/store/RootStoreModel';
import { setupLanguageStore } from '@store/language/LanguageStore';
import { setupOrganizationStore } from '@store/organization/OrganizationStore';

import { setupConfigurationStore } from './configuration/ConfigurationStore';
import { getStoreEnv } from './Environment';
import { setupNetworkStore } from './network/NetworkStore';
import { setupUserStore } from './user/UserStore';

export const setupRootStore = () => {
  const storeEnv = getStoreEnv();
  const rootStore = RootStoreModel.create(
    {
      languageStore: setupLanguageStore(storeEnv),
      userStore: setupUserStore(storeEnv),
      networkStore: setupNetworkStore(storeEnv),
      organizationStore: setupOrganizationStore(storeEnv),
      configurationStore: setupConfigurationStore(storeEnv),
    },
    storeEnv
  );
  return rootStore;
};

const RootStore = setupRootStore();
export default RootStore;
