import { UserOrganization } from '@models/types/Configuration';
import { BackendPermissions, PermissionApp } from '@models/types/Permission';
import {
  getAvailableAdministrationFeatures,
  getAvailableApplicationFeatures,
  permissionsForFeature,
} from '@utils/Permission';

const nonAdminFeatureGroups: string[] = [PermissionApp.Organizations];
const organizationBoundWriteFeatureGroups: string[] = [PermissionApp.DocumentRequests];

const allAppPermissions = getAvailableApplicationFeatures().flatMap((group) => {
  if (nonAdminFeatureGroups.includes(group.id)) {
    return [];
  }
  const readonly = organizationBoundWriteFeatureGroups.includes(group.id);
  return group.features.flatMap((feature) => permissionsForFeature(feature, group.id, readonly));
});

const superAdminPermissions = getAvailableAdministrationFeatures().flatMap((group) => {
  return [
    BackendPermissions.AdministrationDashboardRead,
    ...group.features.flatMap((feature) => permissionsForFeature(feature, group.id)),
  ];
});

export const AdministrationOrganizationId = -1;
export const AdministrationOrganizationName = 'Administration';

export const AdministrationOrganization: UserOrganization = {
  id: AdministrationOrganizationId,
  name: AdministrationOrganizationName,
  admin: true,
  permissions: [...superAdminPermissions, ...allAppPermissions],
};
