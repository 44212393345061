import appConfig from '@appconf/config';
import { DEFAULT_LANGUAGE } from '@models/constants/Language';

export const cleanLng = (lng: string) => {
  // not very elegant but i18n lib sometimes fail to narrow down language detection to
  // main part only (and detects i.e. 'en-US')
  lng = lng.slice(0, 2);

  // sometimes randomly wrong value is set by i18n library, i.e. 'en-US'.
  // Without making sure it's one of supported language we might end up setting
  // current language to something else than we actually support
  if (!(appConfig.supportedLanguages as string[]).includes(lng)) {
    // Tried to set unsupported language. Falling back to default lang
    lng = DEFAULT_LANGUAGE;
  }
  return lng;
};
