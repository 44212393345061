import ErrorFallbackPage from '@components/system/ErrorFallback/ErrorFallback.adapter';
import RouteModel from '@models/types/Route';
import { LoadingView } from '@procivis/react-components';
import * as Sentry from '@sentry/react';
import { FunctionComponent, Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';

export const lazyOutletRouteFromRoute = (route: RouteModel) => (
  <LazyRoute exact path={route.path} key={route.id}>
    <route.component />
  </LazyRoute>
);

export interface LazyRouteProps extends RouteProps {}

const LazyRoute: FunctionComponent<LazyRouteProps> = ({ children, ...props }) => {
  return (
    <Route {...props}>
      <Sentry.ErrorBoundary fallback={(errorData) => <ErrorFallbackPage {...errorData} />}>
        <Suspense fallback={<LoadingView />}>{children}</Suspense>
      </Sentry.ErrorBoundary>
    </Route>
  );
};

export default LazyRoute;
