import {
  CreateOrganizationDTO,
  DocumentNotificationDTO,
  DuplicateOrganizationDTO,
  EditOrganizationDTO,
  OrganizationCreatedEditedDTO,
  OrganizationDetailsDTO,
  OrganizationDocumentTypeDTO,
  OrganizationDTO,
  OrganizationRoleDTO,
  OrganizationServicesDTO,
} from '@models/dtos/Organization';
import {
  CreatedOrganization,
  DocumentsNotificationConfiguration,
  EditedOrganization,
  Organization,
  OrganizationDetails,
  OrganizationDocumentType,
  OrganizationRole,
  OrganizationType,
} from '@models/types/Organization';
import { OrganizationServicesConfiguration } from '@models/types/ServicesConfiguration';
import { TimeSpan } from '@models/types/TimeSpan';
import { nonNil } from '@utils/filtering/NonNilFilter';
import moment from 'moment';

import { addressDTOParser } from './Address';
import { documentTypeMapper, documentTypeParser } from './DocumentOrder';
import { roleDetailsDTOParser } from './Role';
import { notificationFrequencyMapper, notificationFrequencyParser } from './TimeSpan';

export const organizationTypeParser = (type: number): OrganizationType => {
  switch (type) {
    case 1:
      return OrganizationType.Administration;
    case 2:
      return OrganizationType.Government;
    case 3:
      return OrganizationType.Company;
    default:
      return OrganizationType.Unknown;
  }
};

export const organizationTypeMapper = (type: OrganizationType): number | undefined => {
  switch (type) {
    case OrganizationType.Administration:
      return 1;
    case OrganizationType.Government:
      return 2;
    case OrganizationType.Company:
      return 3;
    default:
      return undefined;
  }
};

export const organizationServicesDTOParser = (
  organizationServicesDTO: OrganizationServicesDTO
): OrganizationServicesConfiguration => {
  return {
    documentsEnabled: organizationServicesDTO.enable_documents,
    documentRequestsEnabled: organizationServicesDTO.enable_digital_delivery,
    verificationsEnabled: organizationServicesDTO.enable_validation,
    newsEnabled: organizationServicesDTO.enable_news,
    pollsEnabled: organizationServicesDTO.enable_polls,
    voteInfoEnabled: organizationServicesDTO.enable_voteinfo,
    siteAccessEnabled: organizationServicesDTO.enable_access,
  };
};

export const documentNotificationToDTOParser = (
  notificationConfig: DocumentsNotificationConfiguration,
  prefix: 'document' | 'digital_delivery'
): DocumentNotificationDTO => {
  if (!notificationConfig.notificationTimeSpan || notificationConfig.notificationTimeSpan === TimeSpan.Never) {
    return {
      [`${prefix}_notification_frequency`]: 0,
      [`${prefix}_notification_datetime`]: undefined,
      [`${prefix}_notification_roles`]: [],
    };
  }
  return {
    [`${prefix}_notification_frequency`]: notificationFrequencyMapper(notificationConfig.notificationTimeSpan),
    [`${prefix}_notification_datetime`]: notificationConfig.notificationDateTime?.toISOString(true),
    [`${prefix}_notification_roles`]: notificationConfig.notificationRoles,
  };
};

export const documentNotificationDTOParser = (
  organizationDetailsDTO: OrganizationDetailsDTO
): DocumentsNotificationConfiguration => {
  const timeSpan = notificationFrequencyParser(organizationDetailsDTO.document_notification_frequency);
  const parsedDate = organizationDetailsDTO.document_notification_datetime
    ? moment(organizationDetailsDTO.document_notification_datetime)
    : undefined;
  return {
    notificationTimeSpan: timeSpan,
    notificationDateTime: parsedDate,
    notificationRoles: organizationDetailsDTO.document_notification_roles,
  };
};

export const documenRequeststNotificationDTOParser = (
  organizationDetailsDTO: OrganizationDetailsDTO
): DocumentsNotificationConfiguration => {
  const timeSpan = notificationFrequencyParser(organizationDetailsDTO.digital_delivery_notification_frequency);
  const parsedDate = organizationDetailsDTO.digital_delivery_notification_datetime
    ? moment(organizationDetailsDTO.digital_delivery_notification_datetime)
    : undefined;
  return {
    notificationTimeSpan: timeSpan,
    notificationDateTime: parsedDate,
    notificationRoles: organizationDetailsDTO.digital_delivery_notification_roles,
  };
};

export const organizationDTOParser = (organizationDTO: OrganizationDTO): Organization => {
  return {
    id: organizationDTO.id,
    name: organizationDTO.name,
    type: organizationTypeParser(organizationDTO.organisation_type),
    roles: organizationDTO.roles.map((role) => roleDetailsDTOParser(role)),
    municipality: organizationDTO.municipality,
  };
};

export const organizationDetailsDTOParser = (organizationDetailsDTO: OrganizationDetailsDTO): OrganizationDetails => {
  const documentNotifications = documentNotificationDTOParser(organizationDetailsDTO);
  const documentRequestsNotifications = documenRequeststNotificationDTOParser(organizationDetailsDTO);
  return {
    id: organizationDetailsDTO.id,
    name: organizationDetailsDTO.name,
    type: organizationTypeParser(organizationDetailsDTO.organisation_type),
    address: addressDTOParser({
      street: organizationDetailsDTO.address_line_1,
      extra: organizationDetailsDTO.address_line_2,
      zip_code: organizationDetailsDTO.zip_code,
      city: organizationDetailsDTO.city,
    }),
    phoneNumber: organizationDetailsDTO.phone_number,
    email: organizationDetailsDTO.email,
    website: organizationDetailsDTO.website_url,
    showInApp: organizationDetailsDTO.show_in_app,
    services: organizationServicesDTOParser(organizationDetailsDTO),
    roles: organizationDetailsDTO.organisation_roles
      .map((roleDTO) => organizationRoleDTOParser(roleDTO))
      .map((role) => role.role),
    municipality: organizationDetailsDTO.municipality,
    selectedDocumentTypes: organizationDetailsDTO.organisation_document_types.map((docType) =>
      organizationDocumentTypeDTOParser(docType)
    ),
    documentNotifications,
    documentRequestsNotifications,
    documentRequestReferenceNumberPrefix: organizationDetailsDTO.digital_delivery_reference_number_prefix,
    documentRequestReferenceNumberStartNumber: organizationDetailsDTO.digital_delivery_reference_start_number,
  };
};

export const organizationCreatedEditedDTOParser = (
  organizationCreatedEditedDTO: OrganizationCreatedEditedDTO
): OrganizationDetails => {
  return organizationDetailsDTOParser({
    ...organizationCreatedEditedDTO,
    organisation_roles: [],
    organisation_document_types: [],
  });
};

export const organizationDocumentTypeDTOParser = (
  organizationDocumentTypeDTO: OrganizationDocumentTypeDTO
): OrganizationDocumentType => {
  return {
    id: organizationDocumentTypeDTO.id,
    associatedOrgId: organizationDocumentTypeDTO.organisation,
    type: documentTypeParser(organizationDocumentTypeDTO.document_type),
  };
};

export const organizationRoleDTOParser = (organizationRoleDTO: OrganizationRoleDTO): OrganizationRole => {
  return {
    id: organizationRoleDTO.id,
    associatedOrgId: organizationRoleDTO.organisation,
    role: roleDetailsDTOParser(organizationRoleDTO.role),
  };
};

export const createdOrganizationToDTOParser = (organization: CreatedOrganization): CreateOrganizationDTO => {
  const documentNotifications = organization.documents
    ? documentNotificationToDTOParser(organization.documents, 'document')
    : undefined;
  const documentRequestsNotifications = organization.documentRequests
    ? documentNotificationToDTOParser(organization.documentRequests, 'digital_delivery')
    : undefined;
  return {
    name: organization.name,
    organisation_type: organizationTypeMapper(organization.type) ?? 0,
    address_line_1: organization.address.street,
    address_line_2: organization.address.extra,
    zip_code: organization.address.postcode,
    city: organization.address.city ?? organization.address.location ?? '',
    phone_number: organization.phoneNumber,
    email: organization.email,
    website_url: organization.website,
    enable_documents: organization.services.documentsEnabled,
    enable_digital_delivery: organization.services.documentRequestsEnabled,
    enable_validation: organization.services.verificationsEnabled,
    show_in_app: organization.verifications?.showInApp ?? false,
    enable_news: organization.services.newsEnabled,
    enable_polls: organization.services.pollsEnabled,
    enable_voteinfo: organization.services.voteInfoEnabled,
    enable_access: organization.services.siteAccessEnabled,
    roles: organization.roles.map((role) => role.id),
    document_types: organization.documents?.documentTypes.map(documentTypeMapper).filter(nonNil) ?? [],
    municipality: organization.municipality,
    digital_delivery_reference_number_prefix: organization.documentRequests?.prefix,
    digital_delivery_reference_start_number: organization.documentRequests?.startNumber,
    ...documentNotifications,
    ...documentRequestsNotifications,
  };
};

export const editedOrganizationToDTOParser = (organization: EditedOrganization): EditOrganizationDTO => {
  const createdOrgDTO = createdOrganizationToDTOParser(organization);
  return createdOrgDTO;
};

export const organizationDuplicateDTO = (organisationId: number): DuplicateOrganizationDTO => {
  return {
    organisation: organisationId,
  };
};
