import appConfig from '@appconf/config';
import { getFeaturesMap } from '@appconf/utils';
import { administrationFeatures, applicationFeatures } from '@models/constants/ApplicationFeatures';
import { Feature, FeatureGroup } from '@models/types/Feature';
import { BackendPermissionAction, Permission, PermissionType } from '@models/types/Permission';

export const getAvailableApplicationFeatures = () => {
  const enabledFeatures = getFeaturesMap(appConfig.features);
  return applicationFeatures.filter((group) => (group.featureFlag ? enabledFeatures[group.featureFlag] : true));
};

export const getAvailableAdministrationFeatures = () => {
  const enabledFeatures = getFeaturesMap(appConfig.features);
  return administrationFeatures.filter((group) => (group.featureFlag ? enabledFeatures[group.featureFlag] : true));
};

export const permissionTypesForFeature = (feature: Feature): PermissionType[] => {
  const permissionTypes = [PermissionType.None];
  if (!feature.writeOnly) {
    permissionTypes.push(PermissionType.Read);
  }
  if (!feature.readOnly) {
    permissionTypes.push(PermissionType.Edit);
  }
  return permissionTypes;
};

export const permissionsForFeature = (feature: Feature, groupId: string, readonly = false): string[] => {
  const permissions: string[] = [];
  if (!feature.writeOnly) {
    permissions.push(`${groupId}.${feature.id}.${BackendPermissionAction.Read}`);
  }
  if (!feature.readOnly && !readonly) {
    permissions.push(`${groupId}.${feature.id}.${BackendPermissionAction.Edit}`);
  }
  return permissions;
};

export const featureGroupForPermission = (permission: Permission): FeatureGroup | undefined => {
  const group = getAvailableApplicationFeatures().find((featureGroup) => featureGroup.id === permission.app);
  return group;
};

export const featureForPermission = (permission: Permission): Feature | undefined => {
  const group = featureGroupForPermission(permission);
  const feature = group?.features.find((feature) => feature.id === permission.name);
  return feature;
};
