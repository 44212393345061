import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const UserStoreModel = types
  .model('UserStore', {
    id: types.maybe(types.number),
    username: types.maybe(types.string),
    persistUser: types.boolean,
  })
  // Sync Actions
  .actions((self) => ({
    setId: (id: number | undefined) => {
      self.id = id;
    },
    setUsername: (username: string | undefined) => {
      self.username = username;
    },
    setpersistUser: (shouldPersist: boolean) => {
      self.persistUser = shouldPersist;
    },
    logout: () => {
      self.username = undefined;
      self.id = undefined;
      self.persistUser = false;
    },
  }))
  // Async Actions
  .actions((self) => ({}));

export interface IUserStore extends Instance<typeof UserStoreModel> {}
export interface IUserStoreSnapshotIn extends SnapshotIn<typeof UserStoreModel> {}
export interface IUserStoreSnapshot extends SnapshotOut<typeof UserStoreModel> {}
