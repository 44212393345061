import i18n from '@services/i18n/i18next';

export interface StoreEnv {
  i18nextInstance: typeof i18n;
}

export const getStoreEnv = () => {
  const storeEnv: StoreEnv = { i18nextInstance: i18n };
  return storeEnv;
};
