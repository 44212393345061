/*
 * Initialize app theme.
 */
if (process.env.REACT_APP_TARGET_FLAVOR === 'sh') {
  require('./sh/theme.css');
} else if (process.env.REACT_APP_TARGET_FLAVOR === 'zug') {
  require('./zug/theme.css');
} else if (process.env.REACT_APP_TARGET_FLAVOR === 'ar') {
  require('./ar/theme.css');
} else if (process.env.REACT_APP_TARGET_FLAVOR === 'vfs') {
  require('./vfs/theme.css');
} else {
  require('./procivis/theme.css');
}

export {};
