import appConfig from '@appconf/config';
import TextCrossLogoSVG from '@components/images/TextCrossLogoSVG';
import { Logo } from '@procivis/react-components';
import { FunctionComponent } from 'react';

import styles from './Logo.module.css';

export interface AdaptedLogoProps {
  /**
   * Classname string added to wrapper div
   */
  className?: string;
  /**
   * Uppercase text
   */
  uppercase?: boolean;
  /**
   * Show only logo without app name
   */
  hideName?: boolean;
}

/**
 * Branding logo with app name
 */
const AdaptedLogo: FunctionComponent<AdaptedLogoProps> = ({ className, uppercase = false, hideName = false }) => {
  let iconLogo = <TextCrossLogoSVG />;
  const iconLogoURI = appConfig.theme.images.logoURI;
  if (iconLogoURI) {
    iconLogo = <img src={iconLogoURI} alt={`${appConfig.mobileAppNameShort} ${appConfig.name}`} />;
  }

  const icon = <div className={styles.logo}>{iconLogo}</div>;
  return (
    <Logo
      className={className}
      name={hideName ? '' : uppercase ? appConfig.name.toUpperCase() : appConfig.name}
      icon={icon}
    />
  );
};

export default AdaptedLogo;
