import { CONFIG_STALE_TIME, EMPLOYEE_DETAILS_PATH, SERVER_TIME_PATH } from '@models/constants/API';
import { Configuration } from '@models/types/Configuration';
import { GET } from '@services/api/fetch';
import { configurationDTOParser, serverTimeParser } from '@services/api/parsers/Configuration';
import { useQuery } from 'react-query';

export type ConfigFetcher = (employeeId?: number) => Promise<Configuration>;

export const getConfig: ConfigFetcher = async (employeeId?: number) => {
  if (!employeeId) {
    return Promise.reject();
  }
  return GET(EMPLOYEE_DETAILS_PATH(employeeId)).then(configurationDTOParser);
};

export const useConfiguration = (
  employeeId?: number,
  errorHandler?: (err: any) => void,
  configFetcher: ConfigFetcher = getConfig
) => {
  return useQuery(['configuration', employeeId], () => configFetcher(employeeId), {
    staleTime: CONFIG_STALE_TIME,
    keepPreviousData: true,
    retry: 1,
    enabled: employeeId !== undefined,
    onError: errorHandler,
  });
};

export type TimeFetcher = () => Promise<Date>;

export const getTime: TimeFetcher = async () => {
  return GET(SERVER_TIME_PATH).then(serverTimeParser);
};

export const useServerTime = (timeFetcher: TimeFetcher = getTime) => {
  return useQuery(['time'], () => timeFetcher(), {
    staleTime: CONFIG_STALE_TIME,
    keepPreviousData: true,
    enabled: false,
  });
};
