import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const OrganizationStoreModel = types
  .model('OrganizationStore', {
    currentOrganizationId: types.maybe(types.number),
  })
  // Sync Actions
  .actions((self) => ({
    changeOrganization: (newOrganizationId: number) => {
      if (self.currentOrganizationId !== newOrganizationId) {
        self.currentOrganizationId = newOrganizationId;
      }
    },
    logout: () => {
      self.currentOrganizationId = undefined;
    },
  }));

export interface IOrganizationStore extends Instance<typeof OrganizationStoreModel> {}
export interface IOrganizationStoreSnapshotIn extends SnapshotIn<typeof OrganizationStoreModel> {}
export interface IOrganizationStoreSnapshot extends SnapshotOut<typeof OrganizationStoreModel> {}
