import { useState } from 'react';

export const useSidebarWidth = () => {
  const [tableScrollSize] = useState(
    parseFloat(window.getComputedStyle(document.documentElement).getPropertyValue('--eiddesk-sidebar-width'))
  );

  return tableScrollSize;
};

export const useMinPageWidth = () => {
  const minPageWidth = parseFloat(
    window.getComputedStyle(document.documentElement).getPropertyValue('--eiddesk-min-page-width')
  );
  return minPageWidth;
};

export const useTableScrollSize = () => {
  const sidebarWidth = useSidebarWidth();
  const remSize = parseFloat(window.getComputedStyle(document.documentElement).getPropertyValue('font-size'));
  const minPageWidth = useMinPageWidth();
  const padding = 1.5 * remSize;
  const pageBorderSize = 2;
  const tableScroll = minPageWidth - (sidebarWidth + 4 * padding + pageBorderSize);

  const [tableScrollSize] = useState(tableScroll);

  return tableScrollSize;
};
