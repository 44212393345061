import { Address } from './Address';
import { DocumentType } from './DocumentOrder';
import { Role, RoleDetails } from './Role';
import { OrganizationServicesConfiguration } from './ServicesConfiguration';
import { TimeSpan } from './TimeSpan';

export enum OrganizationType {
  Administration = 'organization.type.administration',
  Government = 'organization.type.government',
  Company = 'organization.type.company',
  Unknown = 'organization.type.unknown',
}

export interface DocumentsNotificationConfiguration {
  notificationTimeSpan?: TimeSpan;
  notificationDateTime?: moment.Moment;
  notificationRoles?: number[];
}

export interface DocumentsConfiguration extends DocumentsNotificationConfiguration {
  documentTypes: DocumentType[];
}

export interface DocumentRequestsConfiguration extends DocumentsNotificationConfiguration {
  prefix: string;
  startNumber: number;
}

export interface VerificationsConfiguration {
  showInApp: boolean;
}

export interface BaseOrganizationInfo {
  id: number;
  name: string;
  type: OrganizationType;
}

export interface Organization extends BaseOrganizationInfo {
  picture?: string;
  roles: RoleDetails[];
  municipality?: number;
}

export interface OrganizationDetails extends Organization {
  selectedDocumentTypes: OrganizationDocumentType[];
  documentNotifications: DocumentsNotificationConfiguration;
  documentRequestsNotifications: DocumentsNotificationConfiguration;
  address: Address;
  phoneNumber?: string;
  email?: string;
  website?: string;
  showInApp: boolean;
  services: OrganizationServicesConfiguration;
  documentRequestReferenceNumberPrefix?: string;
  documentRequestReferenceNumberStartNumber?: number;
}

export interface OrganizationDocumentType {
  id: number;
  associatedOrgId: number;
  type: DocumentType;
}

export interface OrganizationRole {
  associatedOrgId: number;
  id: number;
  role: RoleDetails;
}

export interface CreatedOrganization
  extends Omit<
    OrganizationDetails,
    'id' | 'selectedDocumentTypes' | 'roles' | 'documentNotifications' | 'documentRequestsNotifications'
  > {
  documents?: DocumentsConfiguration;
  documentRequests?: DocumentRequestsConfiguration;
  verifications?: VerificationsConfiguration;
  roles: Role[];
}

export interface EditedOrganization extends CreatedOrganization {
  id: number;
}
