import { ORGANIZATION_STORAGE_KEY } from '@models/constants/Storage';
import {
  IOrganizationStore,
  IOrganizationStoreSnapshot,
  IOrganizationStoreSnapshotIn,
  OrganizationStoreModel,
} from '@models/store/organization/OrganizationStoreModel';
import { StoreEnv } from '@store/Environment';
import { getPersistedData, persistData } from '@store/Storage';
import { onSnapshot } from 'mobx-state-tree';

export const setupOrganizationStore = (env: StoreEnv) => {
  let organizationStore: IOrganizationStore;
  const initData = {
    currentOrganizationId: undefined,
  };

  try {
    const storedOrganizationData = getPersistedData<Partial<IOrganizationStoreSnapshotIn>>(ORGANIZATION_STORAGE_KEY);
    const mergedData = { ...initData, ...storedOrganizationData };

    organizationStore = OrganizationStoreModel.create(mergedData, env);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Couldn't load persistent organization state", e);
    organizationStore = OrganizationStoreModel.create(initData, env);
  }

  onSnapshot(organizationStore, (snapshot) => {
    const persistableData: Partial<IOrganizationStoreSnapshot> = {
      currentOrganizationId: snapshot.currentOrganizationId,
    };

    persistData<Partial<IOrganizationStoreSnapshot>>(persistableData, {}, ORGANIZATION_STORAGE_KEY);
  });

  return organizationStore;
};
