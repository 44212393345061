import ScreenSizeHandlerWrapper from '@components/layout/ScreenSizeHandling/ScreenSizeHandlerWrapper';
import TimeProviderWrapper from '@components/system/TimeProviderWrapper/TimeProviderWrapper';
import { FullScreenView } from '@procivis/react-components';
import AppOutlet from '@router/outlets/App';
import classNames from 'classnames';
import { FunctionComponent } from 'react';

export interface AppProps {
  flavor?: string;
}

const App: FunctionComponent<AppProps> = ({ flavor }) => {
  return (
    <TimeProviderWrapper>
      <ScreenSizeHandlerWrapper>
        <FullScreenView className={classNames(`flavor-${flavor}`)}>
          <AppOutlet />
        </FullScreenView>
      </ScreenSizeHandlerWrapper>
    </TimeProviderWrapper>
  );
};

export default App;
