import { HTTP_AUTH_CODES, LOGIN_PATH } from '@models/constants/API';
import { NETWORK_EVENTS } from '@models/constants/Events';
import { ResponseError } from '@models/error/network';
import { AuthorizationHeaders, DefaultHeaders, FetchOverrides } from '@models/types/Network';
import { reportException } from '@services/error/Reporting';
import EventBus from '@services/events/EventBus';
import RootStore from '@store/RootStore';
import { extractFilename } from '@utils/network/Headers';
import axios, { AxiosProgressEvent } from 'axios';
import { saveAs } from 'file-saver';

import {
  generateEndpointURL,
  generateFetchConfig,
  generatePatchStyleHeaders,
  handleAxiosErrorResponse,
  handleErrorResponse,
  handleJSONResponse,
} from './utils';

const defaultFetchClientOptions: RequestInit = {
  headers: { 'content-type': 'application/json' },
};

const defaultGETClientOptions: RequestInit = {
  headers: { ...defaultFetchClientOptions.headers },
};

const defaultPOSTClientOptions: RequestInit = {
  headers: { ...defaultFetchClientOptions.headers },
};

const defaultDELETEClientOptions: RequestInit = {
  headers: { ...defaultFetchClientOptions.headers },
};

export const GETClient = (
  endpoint: string,
  queryParams: Record<string, any> | undefined = undefined,
  overrides: FetchOverrides = {},
  fetchOptions: RequestInit = {},
  handleError?: (error: ResponseError) => void,
  getAuthHeaders?: () => AuthorizationHeaders,
  getDefaultHeaders?: () => DefaultHeaders
) => {
  // Setup Fetch Request Config
  const { method, ...options } = fetchOptions;
  const fetchConfig: RequestInit = generateFetchConfig(
    defaultGETClientOptions,
    { ...options, method: 'GET' },
    overrides,
    getAuthHeaders?.(),
    getDefaultHeaders?.()
  );

  // Setup Endpoint URL
  const url = generateEndpointURL(endpoint, queryParams);

  return (
    fetch(url, fetchConfig)
      // Handle n-ok response
      .then((response) => {
        return handleErrorResponse(response, handleError);
      })
      // Return data
      .then((response) => {
        return handleJSONResponse(response, overrides);
      })
  );
};

export const PPPClient = (
  endpoint: string,
  queryParams: Record<string, any> | undefined = undefined,
  method: 'POST' | 'PUT' | 'PATCH',
  body: Record<string, any> | BodyInit | undefined = undefined,
  overrides: FetchOverrides = {},
  fetchOptions: RequestInit = {},
  handleError?: (error: ResponseError) => void,
  getAuthHeaders?: () => AuthorizationHeaders,
  getDefaultHeaders?: () => DefaultHeaders
) => {
  // Setup Fetch Request Config
  const fetchConfig: RequestInit = generateFetchConfig(
    defaultPOSTClientOptions,
    { ...fetchOptions, method },
    overrides,
    getAuthHeaders?.(),
    getDefaultHeaders?.()
  );

  // Setup Endpoint URL
  const url = generateEndpointURL(endpoint, queryParams);

  // Setup Request Body
  if (body) {
    if (overrides.keepRawBody) {
      fetchConfig.body = body as BodyInit;
    } else {
      fetchConfig.body = JSON.stringify(body);
    }
  }

  return (
    fetch(url, fetchConfig)
      // Handle n-ok response
      .then((response) => {
        return handleErrorResponse(response, handleError);
      })
      // Return data
      .then((response) => {
        return handleJSONResponse(response, overrides);
      })
  );
};

export const DELETEClient = (
  endpoint: string,
  queryParams: Record<string, any> | undefined = undefined,
  body: Record<string, any> | BodyInit | undefined = undefined,
  overrides: FetchOverrides = {},
  fetchOptions: RequestInit = {},
  handleError?: (error: ResponseError) => void,
  getAuthHeaders?: () => AuthorizationHeaders,
  getDefaultHeaders?: () => DefaultHeaders
) => {
  // Setup Fetch Request Config
  const { method, ...options } = fetchOptions;
  const fetchConfig: RequestInit = generateFetchConfig(
    defaultDELETEClientOptions,
    { ...options, method: 'DELETE' },
    overrides,
    getAuthHeaders?.(),
    getDefaultHeaders?.()
  );

  // Setup Endpoint URL
  const url = generateEndpointURL(endpoint, queryParams);

  // Setup Request Body
  if (body) {
    if (overrides.keepRawBody) {
      fetchConfig.body = body as BodyInit;
    } else {
      fetchConfig.body = JSON.stringify(body);
    }
  }

  return (
    fetch(url, fetchConfig)
      // Handle n-ok response
      .then((response) => {
        return handleErrorResponse(response, handleError);
      })
      // Return data
      .then(() => {
        return undefined;
      })
  );
};

export const DownloadSaveClient = (
  endpoint: string,
  queryParams: Record<string, any> | undefined = undefined,
  body: Record<string, any> | BodyInit | undefined = undefined,
  overrides: FetchOverrides = {},
  fetchOptions: RequestInit = {},
  handleError?: (error: ResponseError) => void,
  getAuthHeaders?: () => AuthorizationHeaders,
  getDefaultHeaders?: () => DefaultHeaders
) => {
  return (
    body
      ? PPPClient(
          endpoint,
          queryParams,
          'POST',
          body,
          { ...overrides, keepRawResponse: true },
          fetchOptions,
          handleError,
          getAuthHeaders,
          getDefaultHeaders
        )
      : GETClient(
          endpoint,
          queryParams,
          { ...overrides, keepRawResponse: true },
          fetchOptions,
          handleError,
          getAuthHeaders,
          getDefaultHeaders
        )
  ).then(async (res: Response) => {
    const fileName = extractFilename(res.headers.get('content-disposition'));
    const blob = await res.blob();
    if (overrides.disableFileSave) {
      return {
        fileName,
        blob,
      };
    }
    saveAs(blob, fileName);
    return fileName;
  });
};

export const UploadClient = (
  endpoint: string,
  queryParams: Record<string, any> | undefined = undefined,
  method: 'POST' | 'PUT' | 'PATCH',
  body: Record<string, any> | BodyInit | undefined = undefined,
  handleError?: (error: ResponseError) => void,
  getAuthHeaders?: () => AuthorizationHeaders,
  getDefaultHeaders?: () => DefaultHeaders,
  progressHandler?: (event: AxiosProgressEvent) => void
) => {
  // Setup Endpoint URL
  const url = generateEndpointURL(endpoint, queryParams);

  return (
    axios
      .postForm(url, body, {
        method,
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders?.(), ...getDefaultHeaders?.() },
        onUploadProgress: progressHandler,
      })
      // Handle n-ok response
      .then((response) => {
        return handleAxiosErrorResponse(response, handleError);
      })
      // Return data
      .then((response) => {
        return response.data;
      })
  );
};

const getAuthHeadersFromStore = (): AuthorizationHeaders => {
  const authHeaders: AuthorizationHeaders = {};

  // Auth Token
  if (RootStore.networkStore.authToken) {
    authHeaders.Authorization = `Token ${RootStore.networkStore.authToken}`;
  }

  // Organization
  if (RootStore.networkStore.orgAssociation) {
    authHeaders['X-Org'] = RootStore.networkStore.orgAssociation;
  }

  return authHeaders;
};

const getDefaultHeadersFromStore = (): DefaultHeaders => {
  const defaultHeaders: DefaultHeaders = {};

  // Accept-Language
  defaultHeaders['Accept-Language'] = RootStore.languageStore.currentLanguage ?? 'de';

  return defaultHeaders;
};

export const emitErrorEvent = (error: ResponseError) => {
  if (error.statusCode >= 400 && !HTTP_AUTH_CODES.includes(error.statusCode)) {
    error.sentryId = reportException(error, NETWORK_EVENTS.ERROR);
  }
  setTimeout(() => {
    EventBus.emit(NETWORK_EVENTS.ERROR, error);
  }, 500);
};

export const GET = (
  endpoint: string,
  queryParams: Record<string, any> | undefined = undefined,
  overrides: FetchOverrides = {},
  options: RequestInit = {}
) => {
  return GETClient(
    endpoint,
    queryParams,
    overrides,
    options,
    emitErrorEvent,
    getAuthHeadersFromStore,
    getDefaultHeadersFromStore
  );
};

export const POST = (
  endpoint: string,
  body: Record<string, any> | BodyInit,
  queryParams: Record<string, any> | undefined = undefined,
  overrides: FetchOverrides = {},
  options: RequestInit = {},
  errorHandler: ((error: ResponseError) => void) | undefined = undefined
) => {
  return PPPClient(
    endpoint,
    queryParams,
    'POST',
    body,
    overrides,
    options,
    errorHandler ? errorHandler : emitErrorEvent,
    getAuthHeadersFromStore,
    getDefaultHeadersFromStore
  );
};

export const PUT = (
  endpoint: string,
  body: Record<string, any> | BodyInit,
  queryParams: Record<string, any> | undefined = undefined,
  overrides: FetchOverrides = {},
  options: RequestInit = {},
  errorHandler: ((error: ResponseError) => void) | undefined = undefined
) => {
  return PPPClient(
    endpoint,
    queryParams,
    'PUT',
    body,
    overrides,
    options,
    errorHandler ? errorHandler : emitErrorEvent,
    getAuthHeadersFromStore,
    getDefaultHeadersFromStore
  );
};

export const PATCH = (
  endpoint: string,
  body: Record<string, any> | BodyInit,
  queryParams: Record<string, any> | undefined = undefined,
  overrides: FetchOverrides = {},
  options: RequestInit = {}
) => {
  const patchOverrides: FetchOverrides = { patchBodyStyle: 'json-merge-patch', ...overrides };
  const patchHeaders: HeadersInit = generatePatchStyleHeaders(patchOverrides);
  return PPPClient(
    endpoint,
    queryParams,
    'PATCH',
    body,
    patchOverrides,
    { ...options, headers: { ...options.headers, ...patchHeaders } },
    emitErrorEvent,
    getAuthHeadersFromStore,
    getDefaultHeadersFromStore
  );
};

export const DELETE = (
  endpoint: string,
  body: Record<string, any> | BodyInit | undefined = undefined,
  queryParams: Record<string, any> | undefined = undefined,
  overrides: FetchOverrides = {},
  options: RequestInit = {}
) => {
  return DELETEClient(
    endpoint,
    queryParams,
    body,
    overrides,
    options,
    emitErrorEvent,
    getAuthHeadersFromStore,
    getDefaultHeadersFromStore
  );
};

export const SubmitForm = (
  endpoint: string,
  body: FormData | string,
  queryParams: Record<string, any> | undefined = undefined,
  overrides: FetchOverrides = {},
  options: RequestInit = {}
) => {
  const isUrlEncoded = typeof body === 'string';
  const formHeaders: HeadersInit = isUrlEncoded ? { 'content-type': 'application/x-www-form-urlencoded' } : {};
  const formOverrides = { ...overrides, disableDefaultOptions: true, keepRawBody: true };
  return PPPClient(
    endpoint,
    queryParams,
    formOverrides.formSubmitStyle ?? 'POST',
    body,
    formOverrides,
    { ...options, headers: { ...options.headers, ...formHeaders } },
    emitErrorEvent,
    getAuthHeadersFromStore,
    getDefaultHeadersFromStore
  );
};

export const DownloadFile = (
  endpoint: string,
  body: Record<string, any> | BodyInit | undefined = undefined,
  queryParams: Record<string, any> | undefined = undefined,
  overrides: FetchOverrides = {},
  options: RequestInit = {}
) => {
  return DownloadSaveClient(
    endpoint,
    queryParams,
    body,
    overrides,
    options,
    emitErrorEvent,
    getAuthHeadersFromStore,
    getDefaultHeadersFromStore
  );
};

export const UploadFile = (
  endpoint: string,
  body: FormData,
  queryParams: Record<string, any> | undefined = undefined,
  progressHandler?: (progress: number | undefined) => void
) => {
  return UploadClient(
    endpoint,
    queryParams,
    'POST',
    body,
    emitErrorEvent,
    getAuthHeadersFromStore,
    getDefaultHeadersFromStore,
    (event: AxiosProgressEvent) => {
      progressHandler?.(event.progress);
    }
  );
};

export const Login = (username: string, password: string) => {
  return PPPClient(
    LOGIN_PATH,
    undefined,
    'POST',
    undefined,
    undefined,
    {
      headers: {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      },
    },
    undefined,
    undefined,
    getDefaultHeadersFromStore
  );
};
