import appConfig from '@appconf/config';
import { ErrorFallbackPage, ErrorFallbackProps } from '@procivis/react-components';
import { isSentryAvailable } from '@services/error/Sentry';
import React, { FunctionComponent } from 'react';

type AdaptedErrorFallbackPageProps = Omit<ErrorFallbackProps, 'isSentryAvailable' | 'appSlug'>;

const AdaptedErrorFallbackPage: FunctionComponent<AdaptedErrorFallbackPageProps> = (errorData) => {
  return <ErrorFallbackPage {...errorData} appSlug={appConfig.flavor} isSentryAvailable={Boolean(isSentryAvailable)} />;
};

export default AdaptedErrorFallbackPage;
