import { InitOptions } from 'i18next';

export const i18nInitialization = (i18nInstance: any) => {
  return new Promise<InitOptions>((resolve) => {
    if (!i18nInstance.isInitialized) {
      i18nInstance.on('initialized', (options: InitOptions) => {
        resolve(options);
      });
    } else {
      resolve(i18nInstance.options);
    }
  });
};
