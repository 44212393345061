import appConfig from '@appconf/config';
import { getFeaturesMap } from '@appconf/utils';
import { AdministrationOrganization } from '@models/constants/Configuration';
import { ConfigurationDTO, ServerTimeDTO } from '@models/dtos/Configuration';
import { Configuration } from '@models/types/Configuration';
import { BackendPermissions } from '@models/types/Permission';
import { featureGroupForPermission } from '@utils/Permission';

import { organizationServicesDTOParser } from './Organization';
import { permissionDTOParser } from './Permission';

export const configurationDTOParser = (configurationDTO: ConfigurationDTO): Configuration => {
  const enabledFeatures = getFeaturesMap(appConfig.features);
  const isSuperAdmin = configurationDTO.is_superadmin;
  const userOrganizations = configurationDTO.organisations.map((organizationDetails) => ({
    id: organizationDetails.id,
    name: organizationDetails.name,
    admin: organizationDetails.user_role.is_admin,
    permissions: [
      BackendPermissions.DashboardRead,
      ...organizationDetails.user_role.permissions.flatMap((permission) => {
        const servicesConfiguration = organizationServicesDTOParser(organizationDetails);
        const featureGroup = featureGroupForPermission(permissionDTOParser(permission));
        if (!featureGroup) {
          return [];
        }
        const featureEnabledOnOrg = featureGroup.featureFlag ? servicesConfiguration[featureGroup.featureFlag] : true;
        const featureEnabledInConfig = featureGroup.featureFlag ? enabledFeatures[featureGroup.featureFlag] : true;
        if (!featureEnabledOnOrg || !featureEnabledInConfig) {
          return [];
        }
        return [`${permission.app}.${permission.name}.${permission.action}`];
      }),
    ],
  }));
  const organizations = isSuperAdmin ? [AdministrationOrganization, ...userOrganizations] : userOrganizations;
  return {
    isSuperAdmin,
    organizations,
  };
};

export const serverTimeParser = (timeDTO: ServerTimeDTO): Date => {
  return new Date(timeDTO.time);
};
