import { PERSISTENT_USER_STORAGE_KEY } from '@models/constants/Storage';
import {
  IUserStore,
  IUserStoreSnapshot,
  IUserStoreSnapshotIn,
  UserStoreModel,
} from '@models/store/user/UserStoreModel';
import { StoreEnv } from '@store/Environment';
import { getPersistedData, persistData } from '@store/Storage';
import { onSnapshot } from 'mobx-state-tree';

export const setupUserStore = (env: StoreEnv) => {
  let userStore: IUserStore;
  const initData = {
    username: undefined,
    persistUser: true,
  };

  try {
    const storedUserData = getPersistedData<Partial<IUserStoreSnapshotIn>>(PERSISTENT_USER_STORAGE_KEY);
    const mergedData = { ...initData, ...storedUserData };

    userStore = UserStoreModel.create(mergedData, env);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Couldn't load persistent user state", e);
    userStore = UserStoreModel.create(initData, env);
  }

  onSnapshot(userStore, (snapshot) => {
    const persistableData: Partial<IUserStoreSnapshot> = {
      id: snapshot.id,
      username: snapshot.username,
      persistUser: snapshot.persistUser,
    };

    if (snapshot.persistUser) {
      persistData<Partial<IUserStoreSnapshot>>(persistableData, {}, PERSISTENT_USER_STORAGE_KEY);
    } else {
      persistData<Partial<IUserStoreSnapshot>>({}, persistableData, PERSISTENT_USER_STORAGE_KEY);
    }
  });

  return userStore;
};
