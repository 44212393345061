import { ResponseError } from '@models/error/network';
import { AuthorizationHeaders, DefaultHeaders, FetchOverrides } from '@models/types/Network';
import { deepMergeObj, omitEmptyFields } from '@utils/objects/Objects';
import { AxiosResponse } from 'axios';
import { map } from 'lodash';

/**
 * Generate Fetch RequestInit Object
 *
 * @param defaultConfig Default Configuration
 * @param additionalConfig Specific Configuration
 * @param overrideFlags Overrides to take into account
 * @param authHeaders Auth Headers to add
 * @returns Combined/Merged RequestInit for fetch
 */
export const generateFetchConfig = (
  defaultConfig: RequestInit,
  additionalConfig: RequestInit,
  overrideFlags: FetchOverrides,
  authHeaders?: AuthorizationHeaders,
  defaultHeaders?: DefaultHeaders
): RequestInit => {
  const fetchConfig: RequestInit = deepMergeObj(
    overrideFlags.disableDefaultOptions ? {} : defaultConfig,
    additionalConfig
  );

  // Headers
  fetchConfig.headers = { ...fetchConfig.headers, ...defaultHeaders, ...authHeaders };

  return fetchConfig;
};

/**
 * Generate API URL
 *
 * @param endpoint API Endpoint URL
 * @param queryParams URL Query Parameters
 * @returns Final API URL to use
 */
export const generateEndpointURL = (endpoint: string, queryParams?: Record<string, any>) => {
  let url = endpoint;

  if (queryParams) {
    const searchParams = new URLSearchParams();

    map(omitEmptyFields(queryParams), (value, key) => {
      if (Array.isArray(queryParams[key])) {
        map(value, (item) => searchParams.append(key, item));
      } else {
        searchParams.append(key, value);
      }
    });
    url = `${endpoint}${searchParams ? `?${searchParams.toString()}` : ''}`;
  }

  return url;
};

/**
 * Handle possible Error State of Response
 *
 * @param response Backend Response
 * @param errorHandler Error handler function
 * @returns Response iff it's ok
 * @throws ResponseError
 */
export const handleErrorResponse = async (response: Response, errorHandler?: (error: ResponseError) => void) => {
  if (!response.ok) {
    let responseBody = undefined;
    try {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const responseJSON = await response.json();
        responseBody = responseJSON;
      } else {
        const responseText = await response.text();
        responseBody = { text: responseText };
      }
    } catch (e) {}
    const exception = new ResponseError(response.status, response.statusText, response.url, responseBody);
    errorHandler?.(exception);
    throw exception;
  }

  return response;
};

/**
 * Handle possible Error State of Response
 *
 * @param response Backend Response
 * @param errorHandler Error handler function
 * @returns Response iff it's ok
 * @throws ResponseError
 */
export const handleAxiosErrorResponse = async (
  response: AxiosResponse<any>,
  errorHandler?: (error: ResponseError) => void
) => {
  if (!(response.status >= 200 && response.status < 300)) {
    let responseBody = undefined;
    try {
      const contentType = response.headers['Content-Type'] as string;
      if (contentType && contentType.includes('application/json')) {
        const responseJSON = response.data;
        responseBody = responseJSON;
      } else {
        const responseText = response.data;
        responseBody = { text: responseText };
      }
    } catch (e) {}
    const exception = new ResponseError(response.status, response.statusText, response.request.url, responseBody);
    errorHandler?.(exception);
    throw exception;
  }

  return response;
};

/**
 * Handle JSON Extraction from Response
 *
 * @param response Backend Response
 * @param overrideFlags Overrides to take into account
 * @returns Response or JSON
 */
export const handleJSONResponse = (response: Response, overrideFlags: FetchOverrides) => {
  if (overrideFlags.keepRawResponse) {
    return response;
  }
  return response.json();
};

/**
 * Determine specialised headers for different PATCH styles
 *
 * json-patch: https://datatracker.ietf.org/doc/html/rfc6902
 * json-merge-patch: https://datatracker.ietf.org/doc/html/rfc7396
 *
 * @param overrideFlags Overrides to take into account
 * @returns Headers object
 */
export const generatePatchStyleHeaders = (overrideFlags: FetchOverrides) => {
  switch (overrideFlags.patchBodyStyle) {
    case 'json-patch':
      return { 'content-type': 'application/json-patch+json' } as HeadersInit;
    case 'json-merge-patch':
      return { 'content-type': 'application/merge-patch+json' } as HeadersInit;
    default:
      return {} as HeadersInit;
  }
};
