export class ResponseError extends Error {
  statusCode: number;
  statusText: string;
  requestURI: string;
  responseBody?: Record<string, any>;
  responseBodyString?: string;
  sentryId?: string;

  constructor(
    status?: number,
    statusText?: string,
    requestURI?: string,
    body?: Record<string, any>,
    sentryId?: undefined
  ) {
    super(`Response Error: ${status} - ${statusText}${body ? `, Message: ${JSON.stringify(body)}` : ''}`);
    this.name = 'ResponseError';
    this.statusCode = status ?? 0;
    this.statusText = statusText ?? 'UNKNOWN';
    this.requestURI = requestURI ?? 'UNKNOWN';
    this.responseBody = body;
    this.responseBodyString = body ? JSON.stringify(body) : undefined;
    this.sentryId = sentryId;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export enum API_VALIDATION_ERROR {
  DUPLICATE_EMAIL = 'Email has already been used',
  PASSWORD_TOO_COMMON = 'This password is too common.',
}
