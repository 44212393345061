import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const NetworkStoreModel = types
  .model('NetworkStore', {
    authToken: types.maybe(types.string),
    persistAuth: types.boolean,
    orgAssociation: types.maybe(types.string),
  })
  // Sync Actions
  .actions((self) => ({
    setAuthToken: (authToken: string | undefined) => {
      self.authToken = authToken;
    },
    setPersistAuth: (shouldPersist: boolean) => {
      self.persistAuth = shouldPersist;
    },
    setOrgAssociation: (org: string | undefined) => {
      self.orgAssociation = org;
    },
    logout: () => {
      self.authToken = undefined;
      self.orgAssociation = undefined;
      self.persistAuth = false;
    },
  }))
  // Async Actions
  .actions((self) => ({}))
  // Derived values
  .views((self) => ({
    get isLoggedIn() {
      return !!self.authToken;
    },
  }));

export interface INetworkStore extends Instance<typeof NetworkStoreModel> {}
export interface INetworkStoreSnapshot extends SnapshotOut<typeof NetworkStoreModel> {}
export interface INetworkStoreSnapshotIn extends SnapshotIn<typeof NetworkStoreModel> {}
