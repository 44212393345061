import { GenerateReportOptions } from '@components/modal/GenerateReport/GenerateReportModal';
import {
  DocumentOrderDetailsDTO,
  DocumentOrderDTO,
  DocumentReportChannelDTO,
  DocumentReportDTO,
} from '@models/dtos/DocumentOrder';
import { DocumentOrder, DocumentOrderDetails, DocumentOrderStatus, DocumentType } from '@models/types/DocumentOrder';
import { DocumentReportChannel } from '@models/types/DocumentReportChannel';
import { dateToSimpleYYYYMMDDString } from '@utils/dates/Dates';

import { simpleSubjectDTOParser, subjectDTOParser } from './User';

export const documentTypeParser = (type: number): DocumentType => {
  switch (type) {
    case 1:
      return DocumentType.DebtRegistry;
    case 2:
      return DocumentType.ResidenceCertificate;
    case 3:
      return DocumentType.HomeId;
    case 4:
      return DocumentType.CivilRightsCapacity;
    case 5:
      return DocumentType.GoodCharacterCertificate;
    case 6:
      return DocumentType.ResidenceCertificateInOut;
    default:
      return DocumentType.Unknown;
  }
};

export const documentTypeMapper = (type: DocumentType): number | undefined => {
  switch (type) {
    case DocumentType.DebtRegistry:
      return 1;
    case DocumentType.ResidenceCertificate:
      return 2;
    case DocumentType.HomeId:
      return 3;
    case DocumentType.CivilRightsCapacity:
      return 4;
    case DocumentType.GoodCharacterCertificate:
      return 5;
    case DocumentType.ResidenceCertificateInOut:
      return 6;
    default:
      return undefined;
  }
};

const documentOrderStatusParser = (status: number): DocumentOrderStatus => {
  switch (status) {
    case 0:
      return DocumentOrderStatus.Created;
    case 1:
      return DocumentOrderStatus.Requested;
    case 2:
      return DocumentOrderStatus.Resolved;
    case 3:
      return DocumentOrderStatus.Automatic;
    case 4:
      return DocumentOrderStatus.Downloaded;
    case 5:
      return DocumentOrderStatus.Error;
    case 6:
      return DocumentOrderStatus.Declined;
    case 7:
      return DocumentOrderStatus.Canceled;
    default:
      return DocumentOrderStatus.Unknown;
  }
};

const reportChannelParser = (channel: DocumentReportChannel | undefined): DocumentReportChannelDTO => {
  switch (channel) {
    case DocumentReportChannel.ResidentsOffice:
      return DocumentReportChannelDTO.city;
    case DocumentReportChannel.DebtEnforcementOffice:
      return DocumentReportChannelDTO.debt;
    case DocumentReportChannel.All:
    default:
      return DocumentReportChannelDTO.all;
  }
};

export const documentOrderDTOParser = (documentOrderDTO: DocumentOrderDTO): DocumentOrder => {
  return {
    id: documentOrderDTO.id,
    type: documentTypeParser(documentOrderDTO.document_type),
    status: documentOrderStatusParser(documentOrderDTO.status),
    user: simpleSubjectDTOParser(documentOrderDTO.subject),
    date: new Date(Date.parse(documentOrderDTO.created_at)),
    completionDate: documentOrderDTO.completed_at ? new Date(Date.parse(documentOrderDTO.completed_at)) : undefined,
    referenceId: documentOrderDTO.reference_number,
  };
};

export const documentOrderDetailsDTOParser = (
  documentOrderDetailsDTO: DocumentOrderDetailsDTO
): DocumentOrderDetails => {
  const { user, ...documentOrder } = documentOrderDTOParser(documentOrderDetailsDTO);
  return {
    ...documentOrder,
    user: subjectDTOParser(documentOrderDetailsDTO.subject),
    completedBy: documentOrderDetailsDTO.completed_by,
    documentName: documentOrderDetailsDTO.document_name,
    comment: documentOrderDetailsDTO.comment,
  };
};

export const reportOptionsToDTOParser = (reportOptions: GenerateReportOptions): DocumentReportDTO => {
  return {
    channel: reportChannelParser(reportOptions.channel),
    start: dateToSimpleYYYYMMDDString(reportOptions.period[0] ?? new Date()),
    end: dateToSimpleYYYYMMDDString(reportOptions.period[1] ?? new Date()),
  };
};
