import { PERSISTENT_AUTH_STORAGE_KEY } from '@models/constants/Storage';
import {
  INetworkStore,
  INetworkStoreSnapshot,
  INetworkStoreSnapshotIn,
  NetworkStoreModel,
} from '@models/store/network/NetworkStoreModel';
import { StoreEnv } from '@store/Environment';
import { getPersistedData, persistData } from '@store/Storage';
import { onSnapshot } from 'mobx-state-tree';

export const setupNetworkStore = (env: StoreEnv) => {
  let networkStore: INetworkStore;
  const initData = {
    authToken: undefined,
    persistAuth: true,
    orgAssociation: undefined,
  };

  try {
    const storedAuthData = getPersistedData<Partial<INetworkStoreSnapshotIn>>(PERSISTENT_AUTH_STORAGE_KEY);
    const mergedData = { ...initData, ...storedAuthData };

    networkStore = NetworkStoreModel.create(mergedData, env);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Couldn't load persistent auth state", e);
    networkStore = NetworkStoreModel.create(initData, env);
  }

  onSnapshot(networkStore, (snapshot) => {
    const persistableData: Partial<INetworkStoreSnapshot> = {
      authToken: snapshot.authToken,
      persistAuth: snapshot.persistAuth,
    };

    if (snapshot.persistAuth) {
      persistData<Partial<INetworkStoreSnapshot>>(persistableData, {}, PERSISTENT_AUTH_STORAGE_KEY);
    } else {
      persistData<Partial<INetworkStoreSnapshot>>({}, persistableData, PERSISTENT_AUTH_STORAGE_KEY);
    }
  });

  return networkStore;
};
