import { PermissionDTO } from '@models/dtos/Permission';
import {
  BackendPermissionAction,
  Permission,
  PermissionApp,
  PermissionName,
  PermissionType,
} from '@models/types/Permission';
import { featureForPermission } from '@utils/Permission';

export const permissionTypeParser = (action: string): PermissionType => {
  switch (action) {
    case BackendPermissionAction.Read:
      return PermissionType.Read;
    case BackendPermissionAction.Edit:
      return PermissionType.Edit;
    default:
      return PermissionType.None;
  }
};

export const permissionDTOParser = (permissionDTO: PermissionDTO): Permission => {
  return {
    name: permissionDTO.name as PermissionName,
    app: permissionDTO.app as PermissionApp,
    type: permissionTypeParser(permissionDTO.action),
  };
};

export const permissionsDTOParser = (permissionsDTO: PermissionDTO[]): Permission[] => {
  const permissions: Permission[] = [];

  for (const permissionDTO of permissionsDTO) {
    const permission = permissionDTOParser(permissionDTO);
    const existingPermissionIndex = permissions.findIndex(
      (p) => p.app === permission.app && p.name === permission.name
    );
    if (existingPermissionIndex === -1) {
      permissions.push(permission);
      continue;
    }
    if (permission.type === PermissionType.Edit) {
      permissions.splice(existingPermissionIndex, 1);
      permissions.push(permission);
    }
  }

  return permissions;
};

export const permissionToDTOParser = (permission: Permission): string[] => {
  if (permission.type === PermissionType.None) {
    return [];
  }
  const feature = featureForPermission(permission);
  if (!feature) {
    return [];
  }
  const permissions: string[] = [];
  if (!feature.writeOnly) {
    permissions.push(`${permission.app}.${permission.name}.${BackendPermissionAction.Read}`);
  }
  if (permission.type === PermissionType.Edit && !feature.readOnly) {
    permissions.push(`${permission.app}.${permission.name}.${BackendPermissionAction.Edit}`);
  }
  return permissions;
};

export const permissionsToDTOParser = (permissions: Permission[]): string[] => {
  return permissions.flatMap(permissionToDTOParser);
};
