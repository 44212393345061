import { ConfigurationStoreModel, IConfigurationStore } from '@models/store/configuration/ConfigurationStoreModel';
import { StoreEnv } from '@store/Environment';

export const setupConfigurationStore = (env: StoreEnv) => {
  let configurationStore: IConfigurationStore;
  const initData = {
    admin: false,
    orgAdmin: false,
  };

  configurationStore = ConfigurationStoreModel.create(initData, env);

  return configurationStore;
};
