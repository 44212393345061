import { useStores } from '@contexts/store/RootStoreContext';
import GuardedRoute from '@router/helpers/GuardedRoute';
import { observer } from 'mobx-react-lite';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface MainGuardedRouteProps {}

const MainGuardedRoute: FunctionComponent<MainGuardedRouteProps> = observer(({ children }) => {
  const location = useLocation();
  const rootStore = useStores();
  const [currentPermission, setCurrentPermission] = useState(rootStore.networkStore.isLoggedIn);

  useEffect(() => {
    if (rootStore.networkStore.isLoggedIn !== currentPermission) {
      setCurrentPermission(rootStore.networkStore.isLoggedIn);
    }
    if (!rootStore.networkStore.isLoggedIn) {
      rootStore.logout();
    }
  }, [rootStore, currentPermission]);

  return (
    <GuardedRoute allowed={currentPermission} path="/" redirectTo={{ pathname: '/login', state: { from: location } }}>
      {children}
    </GuardedRoute>
  );
});

export default MainGuardedRoute;
