import Logo from '@components/branding/Logo/Logo.adapter';
import { FullScreenView, Space, VerticalSegment } from '@procivis/react-components';
import AntTypography from 'antd/lib/typography';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SmallScreenPlaceholder.module.css';

export interface SmallScreenPlaceholderProps {}

/**
 * Small Screen Placeholder
 */
const SmallScreenPlaceholder: FunctionComponent<SmallScreenPlaceholderProps> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <FullScreenView className={styles.container}>
      <Space direction="vertical" align="center">
        <Logo />
        <VerticalSegment centered>
          {t('layout.smallScreen.placeholder')
            .split('\n')
            .map((text) => (
              <AntTypography.Text type="secondary">{text}</AntTypography.Text>
            ))}
        </VerticalSegment>
      </Space>
    </FullScreenView>
  );
};

export default SmallScreenPlaceholder;
