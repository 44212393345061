/* eslint-disable simple-import-sort/imports */
/* eslint-disable import/first */
import 'react-app-polyfill/stable'; // Enable Browserslist based polyfills. This must be the first line!
import './antd.less'; // Antd Styles
import './index.css'; // Global Styles
import '@appconf/theme'; // App Flavor Styles
import '@procivis/react-components/dist/index.css'; // Component library styles

import appConfig from '@appconf/config';
// --- Sentry Init - Before everything else to capture as much as possible
import { initSentry } from '@services/error/Sentry';
initSentry();
import * as Sentry from '@sentry/react';
// ---
import i18n from '@services/i18n/i18next'; // Keep after AppConfig
import { i18nInitialization } from '@utils/i18n/i18n';
import ErrorFallbackPage from '@components/system/ErrorFallback/ErrorFallback.adapter';
import NetworkErrorManager from '@components/system/NetworkErrorManager';
import { RootStoreProvider } from '@contexts/store/RootStoreContext';
import RootRouter from '@router/Root';
import queryClient from '@services/api/query';
import RootStore from '@store/RootStore';
import React, { FunctionComponent, Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';

import App from './App';
import { LoadingView } from '@procivis/react-components';

export interface IndexProps {
  flavor?: string;
}

const Index: FunctionComponent<IndexProps> = ({ flavor }) => {
  // Bootstrap
  useEffect(() => {
    const bootstrap = async () => {
      // --- i18n Init
      await i18nInitialization(i18n);

      // --- Store Init
      await RootStore.languageStore.changeLanguage(i18n.languages[0]);
    };
    bootstrap();
  }, []);

  return (
    <RootStoreProvider value={RootStore}>
      <QueryClientProvider client={queryClient}>
        <RootRouter>
          <Suspense fallback={<LoadingView />}>
            <App flavor={flavor} />
            <NetworkErrorManager />
          </Suspense>
        </RootRouter>
      </QueryClientProvider>
    </RootStoreProvider>
  );
};

// Set Page Title
document.title = `${appConfig.mobileAppNameShort} ${appConfig.name}`;

// Init App
ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={(errorData) => <ErrorFallbackPage {...errorData} />}>
      <Index flavor={appConfig.flavor} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
