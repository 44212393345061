import { useMinPageWidth } from '@hooks/Layout';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { FunctionComponent } from 'react';

import SmallScreenPlaceholder from './SmallScreenPlaceholder';

export interface ScreenSizeHandlerWrapperProps {}

/**
 * Layout Wrapper for blocking small screens
 */
const ScreenSizeHandlerWrapper: FunctionComponent<ScreenSizeHandlerWrapperProps> = ({ children }) => {
  const minPageWidth = useMinPageWidth();
  const [showPlaceholder, setShowPlaceholder] = useState(window.innerWidth < minPageWidth);

  const resizeHandler = useCallback(() => {
    setShowPlaceholder(window.innerWidth < minPageWidth);
  }, [setShowPlaceholder, minPageWidth]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  if (showPlaceholder) {
    return <SmallScreenPlaceholder />;
  }

  return <>{children}</>;
};

export default ScreenSizeHandlerWrapper;
