export enum PermissionApp {
  Dashboard = 'dashboard',
  Organizations = 'organisations',
  Documents = 'documents',
  DocumentRequests = 'digital_delivery',
  Validation = 'validation',
  VoteInfo = 'voteinfo',
  News = 'news',
  Polls = 'polls',
  Access = 'site_access',
  AdministrationDashboard = 'administrationDashboard',
  AdministrationOrganizations = 'administrationOrganisations',
  AdministrationRelyingParties = 'administrationRelyingParties',
  AdministrationLinks = 'administrationLinks',
  AdministrationAuditLogs = 'administrationAuditLogs',
  AdministrationFormGroups = 'administrationFormGroups',
}

export enum PermissionName {
  Dashboard = 'dashboard',
  User = 'user',
  Request = 'request',
  DeliveryRequest = 'deliveryrequest',
  Validation = 'validation',
  Voteday = 'voteday',
  Proposal = 'proposal',
  Excuse = 'excuse',
  News = 'news',
  Poll = 'poll',
  Organization = 'organisation',
  Role = 'role',
  RelyingParty = 'relyingParty',
  ExternalLink = 'externalLink',
  Access = 'site_access',
  AuditLogs = 'auditLogs',
  FormGroups = 'formGroups',
}

export enum PermissionType {
  None = 'permission.none',
  Read = 'permission.read',
  Edit = 'permission.edit',
}

export interface Permission {
  app: PermissionApp;
  name: PermissionName;
  type: PermissionType;
}

export enum BackendPermissionAction {
  Read = 'view',
  Edit = 'change',
}

export const BackendPermissions = {
  DashboardRead: `${PermissionApp.Dashboard}.${PermissionName.Dashboard}.${BackendPermissionAction.Read}`,
  OrganizationsUsersRead: `${PermissionApp.Organizations}.${PermissionName.User}.${BackendPermissionAction.Read}`,
  OrganizationsUsersEdit: `${PermissionApp.Organizations}.${PermissionName.User}.${BackendPermissionAction.Edit}`,
  DocumentOrdersRead: `${PermissionApp.Documents}.${PermissionName.Request}.${BackendPermissionAction.Read}`,
  DocumentOrdersEdit: `${PermissionApp.Documents}.${PermissionName.Request}.${BackendPermissionAction.Edit}`,
  DocumentRequestsRead: `${PermissionApp.DocumentRequests}.${PermissionName.DeliveryRequest}.${BackendPermissionAction.Read}`,
  DocumentRequestsEdit: `${PermissionApp.DocumentRequests}.${PermissionName.DeliveryRequest}.${BackendPermissionAction.Edit}`,
  ValidationsRead: `${PermissionApp.Validation}.${PermissionName.Validation}.${BackendPermissionAction.Read}`,
  ValidationsEdit: `${PermissionApp.Validation}.${PermissionName.Validation}.${BackendPermissionAction.Edit}`,
  VoteInfoRead: `${PermissionApp.VoteInfo}.${PermissionName.Voteday}.${BackendPermissionAction.Read}`,
  VoteInfoEdit: `${PermissionApp.VoteInfo}.${PermissionName.Voteday}.${BackendPermissionAction.Edit}`,
  VoteProposalsEdit: `${PermissionApp.VoteInfo}.${PermissionName.Proposal}.${BackendPermissionAction.Edit}`,
  VoteExcuseRead: `${PermissionApp.VoteInfo}.${PermissionName.Excuse}.${BackendPermissionAction.Read}`,
  NewsRead: `${PermissionApp.News}.${PermissionName.News}.${BackendPermissionAction.Read}`,
  NewsEdit: `${PermissionApp.News}.${PermissionName.News}.${BackendPermissionAction.Edit}`,
  PollsRead: `${PermissionApp.Polls}.${PermissionName.Poll}.${BackendPermissionAction.Read}`,
  PollsEdit: `${PermissionApp.Polls}.${PermissionName.Poll}.${BackendPermissionAction.Edit}`,
  SiteAccessRead: `${PermissionApp.Access}.${PermissionName.Access}.${BackendPermissionAction.Read}`,
  SiteAccessEdit: `${PermissionApp.Access}.${PermissionName.Access}.${BackendPermissionAction.Edit}`,

  // super admin
  AdministrationDashboardRead: `${PermissionApp.AdministrationDashboard}.${PermissionName.Dashboard}.${BackendPermissionAction.Read}`,
  AdministrationOrganizationsRead: `${PermissionApp.AdministrationOrganizations}.${PermissionName.Organization}.${BackendPermissionAction.Read}`,
  AdministrationOrganizationsEdit: `${PermissionApp.AdministrationOrganizations}.${PermissionName.Organization}.${BackendPermissionAction.Edit}`,
  AdministrationRolesRead: `${PermissionApp.AdministrationOrganizations}.${PermissionName.Role}.${BackendPermissionAction.Read}`,
  AdministrationRolesEdit: `${PermissionApp.AdministrationOrganizations}.${PermissionName.Role}.${BackendPermissionAction.Edit}`,
  AdministrationUsersRead: `${PermissionApp.AdministrationOrganizations}.${PermissionName.User}.${BackendPermissionAction.Read}`,
  AdministrationUsersEdit: `${PermissionApp.AdministrationOrganizations}.${PermissionName.User}.${BackendPermissionAction.Edit}`,
  AdministrationRelyingPartiesRead: `${PermissionApp.AdministrationRelyingParties}.${PermissionName.RelyingParty}.${BackendPermissionAction.Read}`,
  AdministrationRelyingPartiesEdit: `${PermissionApp.AdministrationRelyingParties}.${PermissionName.RelyingParty}.${BackendPermissionAction.Edit}`,
  AdministrationExternalLinksRead: `${PermissionApp.AdministrationLinks}.${PermissionName.ExternalLink}.${BackendPermissionAction.Read}`,
  AdministrationExternalLinksEdit: `${PermissionApp.AdministrationLinks}.${PermissionName.ExternalLink}.${BackendPermissionAction.Edit}`,
  AdministrationAuditLogsRead: `${PermissionApp.AdministrationAuditLogs}.${PermissionName.AuditLogs}.${BackendPermissionAction.Read}`,
  AdministrationFormGroupsRead: `${PermissionApp.AdministrationFormGroups}.${PermissionName.FormGroups}.${BackendPermissionAction.Read}`,
  AdministrationFormGroupsEdit: `${PermissionApp.AdministrationFormGroups}.${PermissionName.FormGroups}.${BackendPermissionAction.Edit}`,
};
